@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
* {
  font-family: "Quicksand";
  text-align: center;
  justify-content: center;
  align-items: center;
}

a {
  margin: 20px;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 160px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

.link {
  height: 30px;
  align-items: center;
  color: #000;
  text-decoration: none;
  display: flex;
}

/* Hide extra text */
.mask {
  position: relative;
  padding: 0;
  height: 20px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.link-container {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.title {
  display: block;

  /*  Set same font-size and line height  */
  font-size: 20px;
  line-height: 20px;

  transition: -webkit-transform 0.4s ease;

  transition: transform 0.4s ease;

  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.link-title1 {
  -webkit-transform-origin: right center;
          transform-origin: right center;
  color: white;
}

.link-title2 {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  color: white;
}

.link:hover .link-container {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

/* Rotate texts a little bit */
.link:hover .link-title1 {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.link:hover .link-title2 {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

hr {
  width: 69%;
}

.AllCmds a:link,
.LinksToSee a:link {
  color: #265301;
}

.AllCmds a:visited,
.LinksToSee a:visited {
  color: #437a16;
}

.AllCmds a:focus,
.LinksToSee a:focus {
  border-bottom: 1px solid;
  background: #bae498;
}

.AllCmds a:hover,
.LinksToSee a:hover {
  border-bottom: 1px solid;
  background: #cdfeaa;
}

.AllCmds a:active,
.LinksToSee a:active {
  background: #265301;
  color: #cdfeaa;
}

